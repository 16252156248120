import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import React from "react";
import useCreation from "./useCreation";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import { Link } from "react-router-dom";
import Style from "../campaign.module.scss";
import CreateForm from "./CreateForm";
import CampStop from "./CamStop";
import Filter from "./Filter";
import { Pagination } from "../../Global/Pagination";

const Creation = () => {
  const {
    tabNavs,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    campaignCreateList,
    campaignCreateState,
    selectValues,
    handleSelectedId,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleExportTable,
    handleFilterClick,
    handleFilterClose,
    handleClearClick,
    handleEditClick,
    handleCreateClick,
    handleEditColumnsClick,
    handleCloseCamStopModal,
    updateCampaignCreateFields,
    handleDuplicateClick
  } = useCreation();

  return (
    <div>
      <HeadingGroup
        title={"Campaign"}
        className={`pro-mb-4`}
        buttonTitle={
          campaignCreateList?.data?.permissions?.can_create && "Create"
        }
        handleClick={handleCreateClick}
      />
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={true}
            handleActionClick={handleEditColumnsClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: campaignCreateState?.search }}
            extraFilters={
              <button
                className={"pro-btn pro-items-center pro-btn-outline"}
                onClick={handleFilterClick}
              >
                Filter
              </button>
            }
          />
          {campaignCreateList?.data?.permissions?.can_export && (
            <button
              className={"pro-btn pro-items-center pro-btn-outline "}
              onClick={handleExportTable}
            >
              <span className="material-symbols-outlined x3">
                outgoing_mail
              </span>
              <span>Export Table</span>
            </button>
          )}
        </div>
        {campaignCreateList?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={campaignCreateList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={selectValues?.editIcon}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              editText={selectValues?.editText}
              assignText={"Duplicate"}
              assignIcon={
                <span className="material-symbols-outlined">content_copy</span>
              }
              clear={campaignCreateState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={campaignCreateState?.currentPageSize}
              editable={campaignCreateList?.data?.permissions?.can_update}
              deletable={false}
              assignable={true}
              showCheckBox={campaignCreateList?.data?.permissions?.can_update}
              handleAssign={handleDuplicateClick}
              handleEdit={handleEditClick}
              isSelectBox={true}
              handleSelect={handleSelectedId}
            />
            {campaignCreateList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={campaignCreateState?.currentPage}
                totalPageCount={Math.ceil(
                  campaignCreateList?.data?.total_count /
                    campaignCreateState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          title={"Choose which columns you see"}
        >
          <OrderColumn
            refetchData={refetch}
            tableFields={campaignCreateList?.data?.fields}
            moduleId={campaignCreateList?.data?.table_id}
            updateData={updateCampaignCreateFields}
          />
        </OffCanvasLayout>
        <ModalLayout
          show={campaignCreateState?.campaignStopModal}
          handleClose={handleCloseCamStopModal}
          title={"Stop Campaign"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <div className="pro-m-5">
            <CampStop refetch={refetch} />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={campaignCreateState?.createModal}
          handleClose={handleCreateClick}
          title={
            campaignCreateState?.isEdit
              ? selectValues?.formTitle
              : campaignCreateState?.isDuplicate?"Duplicate Campaign": "Create Campaign"
          }
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          className={Style.offcanvas}
        >
          <CreateForm refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={campaignCreateState?.filter?.modal}
          title="Filter"
          handleClose={handleFilterClose}
        >
          <Filter />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Creation;
